import { default as abuseReports6Bvx2byvc2Meta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93GqXUfMj3O2Meta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as index1IAAEDP4cHMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/admin/index.vue?macro=true";
import { default as moderationKuaCOq0GBHMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/admin/moderation.vue?macro=true";
import { default as pendingBansSW42oCMEH0Meta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/admin/pendingBans.vue?macro=true";
import { default as profilesA7bEkmcOBzMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/admin/profiles.vue?macro=true";
import { default as expensesFRgbnz68baMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexAERN1N8od6Meta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/admin/timesheets/index.vue?macro=true";
import { default as overviewww7igyq9nlMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingCLxR2Tvg84Meta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingdt4PfGBaHSMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/admin/translations/missing.vue?macro=true";
import { default as usersxAm1ZAIT7VMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/admin/users.vue?macro=true";
import { default as apihT05L2JLWaMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/api.vue?macro=true";
import { default as _91slug_93mRadqsHWK0Meta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/blog/[slug].vue?macro=true";
import { default as indexqXMsobkmxBMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93F7JxZ0uuOJMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93GBfMqaMns5Meta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminvJN2SQ3uReMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/census/admin.vue?macro=true";
import { default as indexwHhuRAI03fMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/census/index.vue?macro=true";
import { default as contactjbXtoZ8W8DMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/contact.vue?macro=true";
import { default as designtUt8dS1DZ7Meta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/design.vue?macro=true";
import { default as englishJwQY5dO8hYMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/english.vue?macro=true";
import { default as faqJRaToLUl2lMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/faq.vue?macro=true";
import { default as inclusiveqsowgeK81kMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/inclusive.vue?macro=true";
import { default as indexrcArAOjoHoMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/index.vue?macro=true";
import { default as licenseH05HclBbxiMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/license.vue?macro=true";
import { default as academicsUmI6OJxpMMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/links/academic.vue?macro=true";
import { default as indexXrqG0Bae02Meta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/links/index.vue?macro=true";
import { default as mediaHvmuVAKejpMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/links/media.vue?macro=true";
import { default as translinguisticsncYEwPvBo0Meta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/links/translinguistics.vue?macro=true";
import { default as zinelGSMdde0ByMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/links/zine.vue?macro=true";
import { default as namesrEP8yl1SjEMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/names.vue?macro=true";
import { default as indexvf3fpnAfHVMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/nouns/index.vue?macro=true";
import { default as templateseSHuxZVqXCMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/nouns/templates.vue?macro=true";
import { default as people1PGrj7Nzm2Meta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/people.vue?macro=true";
import { default as privacynRQhObcMKBMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/privacy.vue?macro=true";
import { default as _91username_93omucpintQjMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_93DtETwOetm6Meta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/profile/card-[username].vue?macro=true";
import { default as editorhqtRh5b9wnMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/profile/editor.vue?macro=true";
import { default as anyk5aagE3BqTMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/pronouns/any.vue?macro=true";
import { default as avoiding66xSz1zVopMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/pronouns/avoiding.vue?macro=true";
import { default as index8HEZPeLuOyMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/pronouns/index.vue?macro=true";
import { default as mirrorDCK0Ok46hUMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/pronouns/mirror.vue?macro=true";
import { default as pronounAytvkUsCrPMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/pronouns/pronoun.vue?macro=true";
import { default as sources3fd89pS1hGMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/sources.vue?macro=true";
import { default as teamHZXPXRwgvcMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/team.vue?macro=true";
import { default as terminologyd2JttwiVCaMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/terminology.vue?macro=true";
import { default as termsPqSt4eBv7qMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/terms.vue?macro=true";
import { default as userBN9Jq5UeGWMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/user.vue?macro=true";
import { default as workshopsiFVtZgtW5aMeta } from "/home/admin/www/zaimki.pl/release/20241011063330/pages/workshops.vue?macro=true";
import { default as neutratywy1pA2aUI8UMMeta } from "~/data/nouns/neutratywy.vue?macro=true";
import { default as dukatywyefwx6B0yWaMeta } from "~/data/nouns/dukatywy.vue?macro=true";
import { default as osobatywyJt6U0sHeFZMeta } from "~/data/nouns/osobatywy.vue?macro=true";
import { default as iksatywy4G8TfyCT5SMeta } from "~/data/nouns/iksatywy.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReports6Bvx2byvc2Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBansSW42oCMEH0Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: apihT05L2JLWaMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93mRadqsHWK0Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexqXMsobkmxBMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93F7JxZ0uuOJMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93GBfMqaMns5Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminvJN2SQ3uReMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indexwHhuRAI03fMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactjbXtoZ8W8DMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: englishJwQY5dO8hYMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqJRaToLUl2lMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusiveqsowgeK81kMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/inclusive.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/index.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academicsUmI6OJxpMMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: indexXrqG0Bae02Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediaHvmuVAKejpMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticsncYEwPvBo0Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zinelGSMdde0ByMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: namesrEP8yl1SjEMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: indexvf3fpnAfHVMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templateseSHuxZVqXCMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: people1PGrj7Nzm2Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacynRQhObcMKBMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93omucpintQjMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_93DtETwOetm6Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editorhqtRh5b9wnMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anyk5aagE3BqTMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoiding66xSz1zVopMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: index8HEZPeLuOyMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorDCK0Ok46hUMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronounAytvkUsCrPMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sources3fd89pS1hGMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: teamHZXPXRwgvcMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologyd2JttwiVCaMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsPqSt4eBv7qMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: userBN9Jq5UeGWMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopsiFVtZgtW5aMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241011063330/pages/workshops.vue")
  },
  {
    name: "nouns-neutratywy",
    path: "/neutratywy",
    component: () => import("~/data/nouns/neutratywy.vue")
  },
  {
    name: "nouns-dukatywy",
    path: "/dukatywy",
    component: () => import("~/data/nouns/dukatywy.vue")
  },
  {
    name: "nouns-osobatywy",
    path: "/osobatywy",
    component: () => import("~/data/nouns/osobatywy.vue")
  },
  {
    name: "nouns-iksatywy",
    path: "/iksatywy",
    component: () => import("~/data/nouns/iksatywy.vue")
  }
]