<template>
    <Profile v-if="profile" :user="user" :profile="profile" class="pb-3 mt-5" is-static>
        <nuxt-link to="/">
            <h1 class="text-nowrap h5">
                <Logo style="font-size: 1.3em;" />
                <T>domain</T><span v-if="profile">/@{{ user.username }}</span>
            </h1>
        </nuxt-link>
        <QrCode :url="`https://${$t('domain')}/@${user.username}`" style="display: inline-block; width: 80px; height: 80px; margin-inline-start: 1rem;" />
    </Profile>
    <NotFound v-else />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useNuxtApp, useRoute, useFetch } from 'nuxt/app';
import useSimpleHead from '~/composables/useSimpleHead.ts';
import useConfig from '~/composables/useConfig.ts';

export default defineComponent({
    async setup() {
        definePageMeta({
            translatedPaths: (config) => {
                if (!config.profile.enabled) {
                    return [];
                }
                return ['/card/@:username'];
            },
            layout: 'basic',
        });
        const { $translator: translator } = useNuxtApp();
        const route = useRoute();
        const config = useConfig();
        const username = route.params.username as string;
        useSimpleHead({
            title: `@${username}`,
            banner: `api/banner/@${username}.png`,
        }, translator);

        const { data: user } = await useFetch<any>(`/api/profile/get/${encodeURIComponent(username)}?version=2&lprops[${config.locale}]=all`);

        return {
            config,
            user,
        };
    },
    computed: {
        profile() {
            for (const locale in this.user.profiles) {
                if (locale === this.config.locale) {
                    return this.user.profiles[locale];
                }
            }

            return null;
        },
    },
});
</script>
