<template>
    <Page v-if="config.sources.enabled">
        <h2>
            <Icon v="books" />
            <T>sources.headerLonger</T>
        </h2>

        <p v-if="$t('sources.subheader')">
            <em><T>sources.subheader</T></em>
        </p>

        <section>
            <Share :title="$t('sources.headerLonger')" />
        </section>

        <AdPlaceholder :phkey="['content-0', 'content-mobile-0']" />

        <SourcesChart :sources="sources" label="all pronouns" />

        <Loading :value="sourceLibrary" size="5rem">
            <template v-if="sourceLibrary !== undefined">
                <section v-show="config.sources.submit">
                    <SourceSubmitForm v-show="submitShown" ref="form" />
                    <button v-show="!submitShown" class="btn btn-success w-100" @click="submitShown = true">
                        <Icon v="plus-circle" />
                        <T>sources.submit.header</T>
                    </button>
                </section>

                <section>
                    <button v-if="!tocShown" class="btn btn-outline-primary w-100" @click="tocShown = true">
                        <Icon v="list" />
                        <T>sources.toc</T>
                    </button>
                    <ul v-if="tocShown" class="list-group">
                        <li v-for="[group, groupPronouns] in tocPronounGroups" class="list-group-item">
                            <p class="h5">
                                {{ group.name }}
                            </p>
                            <div v-if="group.description" class="small my-1">
                                <Icon v="info-circle" />
                                <em v-html="group.description"></em>
                            </div>
                            <ul class="list-unstyled">
                                <template v-for="pronoun in groupPronouns">
                                    <li
                                        v-if="config.sources.mergePronouns?.[pronoun.canonicalName] === undefined"
                                        :key="pronoun.canonicalName"
                                    >
                                        <a :href="`#${toId(pronoun.name(glue))}`">
                                            <strong>{{ pronoun.name(glue) }}</strong>
                                            –
                                            <small>{{ pronoun.description }}</small>
                                        </a>
                                        <NormativeBadge v-if="pronoun.normative" />
                                    </li>
                                </template>
                            </ul>
                        </li>
                        <li v-if="sourceLibrary.multiple.length" class="list-group-item">
                            <p class="h5 mb-0">
                                <a :href="`#${$t('pronouns.alt.raw')}`">
                                    <strong><T>pronouns.alt.header</T></strong>
                                </a>
                            </p>
                        </li>
                        <li v-if="sourceLibrary.getForPronoun('', pronounLibrary)" class="list-group-item">
                            <p class="h5 mb-0">
                                <a :href="`#${$t('pronouns.othersRaw')}`">
                                    <strong><T>pronouns.others</T></strong>
                                </a>
                            </p>
                        </li>
                    </ul>
                </section>

                <section v-if="$isGranted('sources')" class="px-3">
                    <div class="alert alert-info">
                        <strong>{{ sourceLibrary.countApproved }}</strong> <T>nouns.approved</T>,
                        <a href="#" @click.prevent="filter.text = '__awaiting__'">
                            <strong>{{ sourceLibrary.countPending }}</strong> <T>nouns.pending</T>.
                        </a>
                    </div>
                </section>

                <ModerationRules type="rulesSources" emphasise />

                <FilterBar
                    v-model="filter.text"
                    v-model:category="filter.category"
                    :categories="categories"
                />

                <template v-for="pronoun in pronouns">
                    <section v-if="config.sources.mergePronouns?.[pronoun.canonicalName] === undefined && sourceLibrary.getForPronoun(pronoun.canonicalName).length">
                        <SourceList
                            :sources="sourceLibrary.getForPronoun(pronoun.canonicalName)"
                            :pronoun="pronoun"
                            :filter="filter.text"
                            :filter-type="filter.category"
                            manage
                            @edit-source="edit"
                        >
                            <h2 :id="toId(pronoun.name(glue))" class="h4">
                                <nuxt-link :to="`/${pronoun.canonicalName}`">
                                    {{ pronoun.description }}
                                    <small>({{ pronoun.name(glue) }})</small>
                                </nuxt-link>
                            </h2>
                        </SourceList>
                    </section>
                </template>

                <a :id="$t('pronouns.alt.raw')"></a>
                <template v-for="multiple in sourceLibrary.multiple">
                    <section v-if="sourceLibrary.getForPronoun(multiple).length">
                        <SourceList
                            :sources="sourceLibrary.getForPronoun(multiple)"
                            :filter="filter.text"
                            :filter-type="filter.category"
                            manage
                            @edit-source="edit"
                        >
                            <h2 :id="toId(multiple)" class="h4">
                                <nuxt-link :to="`/${multiple}`">
                                    <T>pronouns.alt.header</T>
                                    <small>({{ multiple.replace(/&/g, glue) }})</small>
                                </nuxt-link>
                            </h2>
                        </SourceList>
                    </section>
                </template>

                <section v-if="sourceLibrary.getForPronoun('', pronounLibrary)">
                    <SourceList
                        :sources="sourceLibrary.getForPronoun('', pronounLibrary)"
                        :filter="filter.text"
                        :filter-type="filter.category"
                        manage
                        @edit-source="edit"
                    >
                        <h2 :id="$t('pronouns.othersRaw')" class="h4">
                            <T>pronouns.others</T>
                        </h2>
                    </SourceList>
                </section>
            </template>
        </Loading>

        <AdPlaceholder :phkey="['content-1', 'content-mobile-1']" />
    </Page>
</template>

<script lang="ts">
import { useNuxtApp, useFetch } from 'nuxt/app';
import { defineComponent, computed } from 'vue';
import { pronouns, pronounLibrary } from '../src/data.ts';
import { Source, SourceLibrary } from '../src/classes.ts';
import useSimpleHead from '../composables/useSimpleHead.ts';
import type { SourceRaw } from '../src/classes.ts';
import type SourceSubmitForm from '../components/SourceSubmitForm.vue';
import useConfig from '../composables/useConfig.ts';

interface Refs {
    form: InstanceType<typeof SourceSubmitForm> | undefined;
}

export default defineComponent({
    async setup() {
        definePageMeta({
            translatedPaths: (config) => translatedPathByConfigModule(config.sources),
        });

        const { $translator: translator } = useNuxtApp();
        const config = useConfig();
        useSimpleHead({
            title: translator.translate('sources.headerLonger'),
            description: translator.translate('sources.subheader'),
        }, translator);

        const filter = useFilterWithCategory();

        const { data: sources } = await useFetch<SourceRaw[]>('/api/sources', { lazy: true });
        const sourceLibrary = computed(() => {
            if (sources.value === null) {
                return undefined;
            }
            return new SourceLibrary(config, sources.value);
        });

        return {
            config,
            filter,
            sources,
            sourceLibrary,
        };
    },
    data() {
        return {
            pronouns,
            pronounLibrary,
            tocShown: false,
            sourceTypes: Source.TYPES,
            glue: ` ${this.$t('pronouns.or')} `,
            submitShown: false,
        };
    },
    computed: {
        $tRefs(): Refs {
            return this.$refs as unknown as Refs;
        },
        tocPronounGroups() {
            const pronounGroups = this.pronounLibrary.split((pronoun) => {
                if (this.sourceLibrary === undefined) {
                    return false;
                }
                return this.sourceLibrary.getForPronoun(pronoun.canonicalName).length > 0;
            }, false);
            return [...pronounGroups].filter(([_, groupPronouns]) => groupPronouns.length > 0);
        },
        categories() {
            return Object.entries(Source.TYPES)
                .filter(([type]) => type)
                .map(([type, icon]) => ({
                    key: type,
                    text: this.$t(`sources.type.${type}`),
                    icon,
                }));
        },
    },
    methods: {
        toId(str: string): string {
            return str.replace(/\//g, '-').replace(/&/g, '_');
        },
        edit(source: Source) {
            this.submitShown = true;
            this.$nextTick(() => {
                this.$tRefs.form?.edit(source);
            });
        },
    },
});
</script>
