<template>
    <Page v-if="config.api">
        <h2>
            <Icon v="laptop-code" />
            <T>api.header</T>
        </h2>

        <section v-for="group in groups.filter((group) => group.enabled)" class="py-2">
            <h3>
                <Icon :v="group.icon" />
                <T>{{ group.header }}</T>
            </h3>
            <ul>
                <li v-for="([method, path, queryString, notes], endpoint) in group.endpoints" class="my-3">
                    <p>
                        <span class="badge bg-primary text-white">{{ method }}</span>
                        <code>{{ path }}</code>
                        <a
                            v-for="example in config.api.examples[endpoint]"
                            :href="$config.public.baseUrl + example"
                            class="badge bg-light text-dark border mx-1"
                            target="_blank"
                            rel="noopener"
                        >
                            <Icon v="cog" />
                            <T>api.example</T>
                        </a>
                    </p>
                    <ul v-if="notes" class="small">
                        <li v-for="note in notes">
                            <span v-html="note"></span>
                        </li>
                    </ul>
                    <p v-if="queryString" class="mt-2 mb-0 small bold">
                        <T>api.query</T><T>quotation.colon</T>
                    </p>
                    <ul v-if="queryString" class="small">
                        <li v-for="(description, param) in queryString">
                            <code>{{ param }}</code> – <span v-html="description"></span>
                        </li>
                    </ul>
                </li>
            </ul>
        </section>
    </Page>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useNuxtApp } from 'nuxt/app';
import useConfig from '../composables/useConfig.ts';
import useSimpleHead from '../composables/useSimpleHead.ts';

interface Group {
    enabled: boolean | undefined;
    header: string;
    icon: string;
    endpoints: Record<string, Endpoint>;
}

type Endpoint = [string, string, Record<string, string>?, string[]?];

function getProfile(routePart: string, param: string): Endpoint {
    return ['GET', `/api/profile/${routePart}/{${param}}?version=2`, {
        props: 'Specify what properties that the response should include. How to specify them is described <a href=\'https://gitlab.com/PronounsPage/PronounsPage/-/merge_requests/345#table-of-keys\'> here.',
        'lprops[<locale>]': 'Same as the <code>props</code> parameter, but only for specific locales. Replace <code>&lt;locale&gt;</code> with the locale you wish to override the filter for.',
    }, ['Note that the <code>birthday</code> field will only be available when querying your own account; otherwise only the calculated <code>age</code> might be available (if the person has filled out their birthday)']];
}

export default defineComponent({
    setup() {
        definePageMeta({
            translatedPaths: (config) => {
                if (config.api === null) {
                    return [];
                }
                return ['/api'];
            },
        });

        const { $translator: translator } = useNuxtApp();
        useSimpleHead({
            title: translator.translate('api.header'),
        }, translator);
        return {
            config: useConfig(),
        };
    },
    computed: {
        groups(): Group[] {
            return [{
                enabled: this.config.pronouns.enabled,
                header: 'home.header',
                icon: 'tags',
                endpoints: {
                    pronouns_all: ['GET', '/api/pronouns'],
                    pronouns_one: ['GET', '/api/pronouns/{pronoun}', {
                        'examples[]': 'Overwrite the default example sentences with custom ones. For each of them use the following format: <code>{sentenceSingular}|{sentencePlural}|{isHonorific}</code>. If <code>sentencePlural</code> is missing, if defaults to being the same as <code>sentenceSingular</code>. <code>isHonorific</code> can be <code>0</code> (default) or <code>1</code>.',
                    }],
                    pronouns_banner: ['GET', '/api/banner/{pronoun}.png'],
                },
            }, {
                enabled: this.config.sources.enabled,
                header: 'sources.header',
                icon: 'books',
                endpoints: {
                    sources_all: ['GET', '/api/sources'],
                    sources_one: ['GET', '/api/sources/{id}'],
                },
            }, {
                enabled: this.config.nouns.enabled,
                header: 'nouns.header',
                icon: 'atom-alt',
                endpoints: {
                    nouns_all: ['GET', '/api/nouns'],
                    nouns_search: ['GET', '/api/nouns/search/{term}'],
                },
            }, {
                enabled: this.config.inclusive.enabled,
                header: 'inclusive.header',
                icon: 'book-heart',
                endpoints: {
                    inclusive_all: ['GET', '/api/inclusive'],
                    inclusive_search: ['GET', '/api/inclusive/search/{term}'],
                },
            }, {
                enabled: this.config.terminology.enabled,
                header: 'terminology.header',
                icon: 'flag',
                endpoints: {
                    terms_all: ['GET', '/api/terms'],
                    terms_search: ['GET', '/api/terms/search/{term}'],
                },
            }, {
                enabled: this.config.profile.enabled,
                header: 'profile.header',
                icon: 'id-card',
                endpoints: {
                    profile_get: getProfile('get', 'username'),
                    profile_get_by_id: getProfile('get-id', 'id'),
                    user_socialLookup: ['GET', '/api/user/social-lookup/{provider}/{identifier}', undefined],
                },
            }, {
                enabled: this.config.calendar?.enabled,
                header: 'calendar.header',
                icon: 'calendar-star',
                endpoints: {
                    calendar_today: ['GET', '/api/calendar/today'],
                    calendar_day: ['GET', '/api/calendar/{day}'],
                },
            }];
        },
    },
});
</script>
