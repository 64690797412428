import { Pronoun, PronounGroup } from './classes.ts';
import Compressor from './compressor.ts';
import { buildDict, buildList, isEmoji, splitSlashes, unescapeControlSymbols } from './helpers.ts';
import MORPHEMES from '../data/pronouns/morphemes.ts';
import type { Config, NullPronounsConfig } from '../locale/config.ts';
import type { Translator } from './translator.ts';
import type { PronounData, PronounGroupsData } from '../locale/data.ts';

export const addAliasesToPronouns = (pronouns: Record<string, Pronoun>): Record<string, Pronoun> => {
    const pronounsWithAliases: Record<string, Pronoun> = {};
    for (const [base, pronoun] of Object.entries(pronouns)) {
        pronounsWithAliases[base] = pronoun;
        for (const alias of pronoun.aliases) {
            pronounsWithAliases[alias] = pronoun;
        }
    }
    return pronounsWithAliases;
};

export const getPronoun = (pronouns: Record<string, Pronoun>, id: string): Pronoun | undefined => {
    return addAliasesToPronouns(pronouns)[id];
};

const conditionalKeyPlaceHolder = /#\/([^/]+)\/(\w+)?#/;
const unconditionalKeyPlaceholder = /#/g;

const buildMorphemeFromTemplate = (key: string, template: string): string => {
    const variants = template.split('|');
    for (const variant of variants) {
        const conditionalMatch = variant.match(conditionalKeyPlaceHolder);
        if (conditionalMatch) {
            if (key.match(new RegExp(conditionalMatch[1], conditionalMatch[2]))) {
                return variant.replace(conditionalKeyPlaceHolder, key);
            }
        } else {
            return variant.replace(unconditionalKeyPlaceholder, key);
        }
    }
    return template;
};

interface PronounTemplate {
    description: string;
    normative?: boolean;
    morphemes: Record<string, string>;
    plural?: boolean;
    pluralHonorific?: boolean;
    aliases?: string[];
    history?: string;
}

const buildPronounFromTemplate = (config: Config, key: string, template: PronounTemplate): Pronoun => {
    return new Pronoun(
        config,
        key,
        template.description,
        template.normative || false,
        buildDict(function*(morphemes) {
            for (const m of MORPHEMES) {
                yield [m, Object.hasOwn(morphemes, m) ? buildMorphemeFromTemplate(key, morphemes[m]) : null];
            }
        }, template.morphemes),
        [template.plural || false],
        [template.pluralHonorific || false],
        template.aliases || [],
        `${template.history || ''}@__generator__`.replace(/^@/, ''),
        false,
    );
};

export const NULL_PRONOUNS_MAXLENGTH = 32;

const isModifier = (chunk: string, key: string, translator: Translator): boolean => {
    // use both locale and base translations to ensure backwards compatibility if key gets translated
    return chunk === `:${translator.translate(key)}` || chunk === `:${translator.get(key, false, true)}`;
};

const extractModifierValue = (chunk: string, key: string, translator: Translator): string | null => {
    // use both locale and base translations to ensure backwards compatibility if key gets translated
    const prefixes = [`:${translator.translate(key)}=`, `:${translator.get(key, false, true)}=`];
    for (const prefix of prefixes) {
        if (chunk.startsWith(prefix)) {
            return chunk.substring(prefix.length);
        }
    }
    return null;
};

const buildPronounFromSlashes = (config: Config, path: string, translator: Translator): Pronoun | null => {
    if (!config.pronouns.generator?.enabled) {
        return null;
    }
    const chunks = splitSlashes(path);
    let plural = false;
    let pluralHonorific = false;
    let description = '';
    const morphemeChunks: (string | null)[] = [];
    for (const chunk of chunks) {
        if (chunk.startsWith(':')) {
            if (config.pronouns.plurals && isModifier(chunk, 'pronouns.slashes.plural', translator)) {
                plural = true;
            } else if (config.pronouns.plurals && config.pronouns.honorifics &&
                isModifier(chunk, 'pronouns.slashes.pluralHonorific', translator)) {
                pluralHonorific = true;
            } else {
                const descriptionModifierValue =
                    extractModifierValue(chunk, 'pronouns.slashes.description', translator);
                if (descriptionModifierValue) {
                    description = unescapeControlSymbols(descriptionModifierValue)!;
                }
            }
        } else {
            if (chunk === '~') {
                morphemeChunks.push(null);
            } else if (chunk === ' ') {
                morphemeChunks.push('');
            } else {
                morphemeChunks.push(unescapeControlSymbols(chunk));
            }
        }
    }
    if (description.length > Pronoun.DESCRIPTION_MAXLENGTH) {
        return null;
    }
    const slashMorphemes = config.pronouns.generator.slashes === true
        ? MORPHEMES
        : config.pronouns.generator.slashes;
    if (slashMorphemes && morphemeChunks.length === slashMorphemes.length) {
        return new Pronoun(
            config,
            `${morphemeChunks[0]}/${morphemeChunks[1]}`,
            description,
            false,
            buildDict(function*() {
                for (const m of MORPHEMES) {
                    const index = slashMorphemes.indexOf(m);
                    yield [m, index === -1 ? null : morphemeChunks[index]];
                }
            }),
            [plural],
            [pluralHonorific],
            [],
            '__generator__',
            false,
        );
    }
    return null;
};

export const buildPronoun = (
    pronouns: Record<string, Pronoun>,
    path: string | null,
    config: Config,
    translator: Translator,
): Pronoun | null => {
    if (!path || !config.pronouns.enabled) {
        return null;
    }

    for (const prefix of config.pronouns.sentence ? config.pronouns.sentence.prefixes : []) {
        if (`/${path}`.startsWith(`${prefix}/`)) {
            path = path.substring(prefix.length);
            break;
        }
    }

    const pronounsWithAliases = addAliasesToPronouns(pronouns);

    let pronounStr: (string | null)[] = path.split(',');

    let base: Pronoun | null | undefined = null;
    for (const option of pronounStr[0]!.split('&')) {
        if (!base) {
            base = pronounsWithAliases[option] ?? null;
        } else if (pronounsWithAliases[option]) {
            base = base.merge(pronounsWithAliases[option]);
        }
    }
    let baseArray = base ? base.toArray() : null;
    // i know, it's ugly… didn't think about BC much and now it's a huge mess…
    const pronounStrLen = pronounStr.map((x) => x!.startsWith('!') ? parseInt(x!.substring(1)) : 1).reduce((c, a) => c + a, 0);
    if (config.locale === 'de') {
        // only migrate the four original morphemes as the generator has not supported more morphemes
        const oldMorphemeVersions = [
            ['pronoun_n', 'pronoun_d', 'pronoun_a', 'possessive_determiner_m_n'],
            ['pronoun_n', 'pronoun_d', 'pronoun_a', 5, 'possessive_determiner_m_n', 15]
                .flatMap((morphemeOrIgnoredCount) => {
                    if (typeof morphemeOrIgnoredCount === 'string') {
                        return [morphemeOrIgnoredCount];
                    }
                    return new Array(morphemeOrIgnoredCount).fill(null);
                }),
        ];
        for (const oldMorphemeVersion of oldMorphemeVersions) {
            if (pronounStrLen === oldMorphemeVersion.length + 2) {
                const baseArrayWithDowngradedMorphemes = oldMorphemeVersion.map((morpheme) => {
                    if (morpheme === null || !base) {
                        return null;
                    }
                    return base.morphemes[morpheme];
                }).concat(baseArray ? baseArray.slice(baseArray.length - 2) : ['0', '']);
                const uncompressed = Compressor.uncompress(pronounStr, baseArrayWithDowngradedMorphemes, config.locale);
                pronounStr = MORPHEMES.map((morpheme) => {
                    const index = oldMorphemeVersion.indexOf(morpheme);
                    if (index >= 0) {
                        return uncompressed[index];
                    }
                    return null;
                }).concat(uncompressed.slice(uncompressed.length - 2));
                break;
            }
        }
    } else if (config.locale === 'pl' && baseArray && pronounStrLen < 31) {
        baseArray.splice(baseArray.length - 10, 1);
        if (pronounStrLen < 30) {
            baseArray = [
                ...baseArray.slice(0, 4),
                baseArray[5],
                baseArray[8],
                ...baseArray.slice(11),
            ];
        }
        if (pronounStrLen < 24) {
            baseArray.splice(2, 1);
        } else if (pronounStrLen < 23) {
            baseArray.splice(8, 1);
            baseArray.splice(2, 1);
        } else if (pronounStrLen < 22) {
            baseArray.splice(8, 1);
            baseArray.splice(8, 1);
            baseArray.splice(2, 1);
        }
    }

    let pronoun = pronounStr.length === 1
        ? base
        : Pronoun.from(Compressor.uncompress(pronounStr, baseArray, config.locale), config);

    if (!pronoun && config.pronouns.emoji !== false && isEmoji(path)) {
        pronoun = buildPronounFromTemplate(config, path, config.pronouns.emoji);
    }

    if (!pronoun && config.pronouns.null && config.pronouns.null.morphemes && path.startsWith(':') &&
        path.length <= NULL_PRONOUNS_MAXLENGTH + 1) {
        const template = config.pronouns.null as NullPronounsConfig & { morphemes: Record<string, string> };
        pronoun = buildPronounFromTemplate(config, path.substring(1), template);
    }

    if (!pronoun && config.pronouns.generator.slashes !== false) {
        return buildPronounFromSlashes(config, path, translator);
    }

    return pronoun;
};

export const parsePronouns = (
    config: Config,
    pronounsRaw: PronounData<string>[],
): Record<string, Pronoun> => {
    return buildDict(function* () {
        for (const t of pronounsRaw) {
            const aliases = t.key.replace(/،/g, ',').split(',');

            yield [
                aliases[0],
                new Pronoun(
                    config,
                    aliases[0],
                    t.description,
                    t.normative,
                    buildDict(function* () {
                        for (const morpheme of MORPHEMES) {
                            let value;
                            if (t[morpheme] === null) {
                                // empty cells are parsed as null in dynamic parse mode,
                                // but most of the time an empty string is intended
                                value = '';
                            } else if (t[morpheme] === '~') {
                                // to really describe that a pronoun does not support a morpheme,
                                // tilde is used to describe null as in yaml.
                                value = null;
                            } else {
                                value = t[morpheme];
                            }
                            yield [morpheme, value];
                        }
                    }),
                    [t.plural],
                    [t.pluralHonorific],
                    aliases.slice(1),
                    t.history,
                    t.pronounceable,
                    t.thirdForm,
                    t.smallForm,
                    t.sourcesInfo,
                    t.hidden ?? false,
                ),
            ];
        }
    });
};

export const parsePronounGroups = (pronounGroupsRaw: PronounGroupsData[]): PronounGroup[] => {
    return buildList(function* () {
        for (const g of pronounGroupsRaw) {
            yield new PronounGroup(
                g.name,
                g.pronouns ? g.pronouns.replace(/،/g, ',').split(',') : [],
                g.description,
                g.key || null,
                g.hidden ?? false,
            );
        }
    });
};
