<template>
    <Page>
        <NotFound v-if="!content" />
        <div v-else ref="post" class="blog-post">
            <p v-if="!config.links.split">
                <nuxt-link v-if="config.links.blog" :to="{ name: 'blog' }">
                    <Icon v="pen-nib" />
                    <T>links.blog</T>
                </nuxt-link>
            </p>

            <AdPlaceholder :phkey="['content-0', 'content-mobile-0']" />

            <Spelling :text="content" />

            <AdPlaceholder :phkey="['content-1', 'content-mobile-1']" />

            <div class="d-print-none">
                <Separator icon="heart" />
                <BlogReactionSection :slug="slug" />
                <section>
                    <Share :title="title ?? undefined" />
                </section>
                <Support />
            </div>
        </div>
    </Page>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useNuxtApp, useRoute } from 'nuxt/app';
import useSimpleHead from '~/composables/useSimpleHead.ts';
import parseMarkdown from '~/src/parseMarkdown.ts';
import Columnist from 'avris-columnist';
import useConfig from '~/composables/useConfig.ts';
import type { MarkdownInfo } from '~/src/parseMarkdown.ts';

export default defineComponent({
    async setup() {
        definePageMeta({
            translatedPaths: (config) => {
                if (!config.links.enabled || !config.links.blog) {
                    return [];
                }
                const shortcuts = Object.entries(config.blog?.shortcuts ?? {})
                    .filter(([_shortcut, slug]) => !(config.blog?.keepFullPath ?? []).includes(slug))
                    .map(([shortcut, slug]) => {
                        const shortcutRoute = { paths: [`/${encodeURIComponent(shortcut)}`], meta: { slug } };
                        return [`blogEntryShortcut:${shortcut}`, shortcutRoute] as const;
                    });
                return {
                    blogEntry: { paths: [`/${encodeURIComponent(config.links.blogRoute)}/:slug`] },
                    ...Object.fromEntries(shortcuts),
                };
            },
        });

        const { $translator: translator } = useNuxtApp();
        const route = useRoute();
        const slug = (route.params.slug ?? route.meta.slug) as string;

        const blogEntry = ref<MarkdownInfo>({ title: null, img: null, intro: null, content: null });

        useSimpleHead({
            title: computed(() => blogEntry.value.title),
            description: computed(() => blogEntry.value.intro),
            banner: computed(() => blogEntry.value.img),
        }, translator);

        const post = useTemplateRef<HTMLDivElement>('post');

        try {
            const markdown = (await import(`~/data/blog/${slug}.md`)).default;
            blogEntry.value = await parseMarkdown(markdown, translator);
        } catch (error) {
            // import failed
        }

        return {
            config: useConfig(),
            slug,
            title: blogEntry.value.title,
            content: blogEntry.value.content,
            post,
        };
    },
    data() {
        return {
            selGroup: 'general',
        };
    },
    async mounted() {
        if (!this.content) {
            return;
        }
        if (this.content.includes('twitter-tweet')) {
            await this.$loadScript('twitter', 'https://platform.twitter.com/widgets.js');
        }

        if (this.content.includes('fb-post')) {
            var divElement = document.createElement('div');
            divElement.id = 'fb-root';
            document.body.appendChild(divElement);

            await this.$loadScript('facebook', 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v19.0', 'xwbrn1J4');
        }

        for (const wall of this.post?.querySelectorAll('.columnist-wall') ?? []) {
            const columnist = new Columnist(wall);
            columnist.start();
        }
    },
});
</script>

<style lang="scss">
    @import "assets/variables";

    .blog-post {
        hyphens: auto;
        text-align: justify;

        h1 {
            text-align: left;
        }

        img {
            max-width: 100%;
        }

        figure {
            width: 100%;
            max-width: 24rem;
            padding: $spacer;
            img {
                width: 100%;
            }
            figcaption {
                margin-top: $spacer / 2;
                font-size: $small-font-size;
            }
        }

        .forms-grid {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(18rem, 3fr));
            grid-gap: $spacer;
            justify-items: center;
            figure {
                padding: 0;
                figcaption {
                    font-size: 90%;
                }
            }
        }

        hr {
            border: none;
            border-top: 3px solid $primary;
            opacity: 1;
        }
    }

   .columnist-wall > .columnist-column {
       transition: margin-top .2s ease-in-out;
   }
</style>
