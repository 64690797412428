<template>
    <Page>
        <h2
            class="d-flex justify-content-between align-items-start align-items-md-center
                flex-column flex-md-row gap-2"
        >
            <span>
                <Icon v="pen-nib" />
                <T>links.blog</T>
            </span>
            <a href="/blog.atom" target="_blank" rel="noopener" class="btn btn-sm btn-outline-primary">
                <T icon="rss">links.blogFeed</T>
            </a>
        </h2>
        <AdPlaceholder :phkey="['content-0', 'content-mobile-0']" />
        <Loading :value="posts">
            <BlogEntriesList :posts="posts" details />
        </Loading>
        <Separator icon="heart" />
        <Support />
        <section>
            <Share :title="$t('links.blog')" />
        </section>
    </Page>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useFetch, useNuxtApp } from 'nuxt/app';
import useSimpleHead from '~/composables/useSimpleHead.ts';

export default defineComponent({
    async setup() {
        definePageMeta({
            translatedPaths: (config) => {
                if (!config.links.enabled || !config.links.blog) {
                    return [];
                }
                return [`/${encodeURIComponent(config.links.blogRoute)}`];
            },
        });

        const { $translator: translator } = useNuxtApp();
        useSimpleHead({
            title: translator.translate('links.blog'),
        }, translator);

        const { data: posts } = useFetch('/api/blog', { lazy: true });

        return {
            posts,
        };
    },
});
</script>
