<template>
    <Page>
        <CommunityNav />

        <h2>
            <Icon v="flag" />
            <T>terminology.headerLong</T>
        </h2>

        <section>
            <T>terminology.info</T>
            <Share :title="$t('terminology.headerLong')" />
        </section>

        <ModerationRules type="rulesTerminology" emphasise />

        <AdPlaceholder :phkey="['content-0', 'content-mobile-0']" />

        <TermsDictionary load />
    </Page>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useNuxtApp } from 'nuxt/app';
import useSimpleHead from '../composables/useSimpleHead.ts';
import useConfig from '../composables/useConfig.ts';

export default defineComponent({
    setup() {
        definePageMeta({
            translatedPaths: (config) => translatedPathByConfigModule(config.terminology),
        });

        const { $translator: translator } = useNuxtApp();
        useSimpleHead({
            title: translator.translate('terminology.headerLong'),
            description: translator.translate('terminology.info')[0],
        }, translator);
        return {
            config: useConfig(),
        };
    },
});
</script>
