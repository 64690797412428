import routerOptions0 from "/home/admin/www/zaimki.pl/release/20241011063330/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.5_eslint@8.57.1_rollup@4.22.2_sass@1.32.12__ydqwum6exgwbk22mhj5oetqsgm/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/home/admin/www/zaimki.pl/release/20241011063330/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}