export default [
    'pronoun_n',
    'pronoun_possessive',
    'pronoun_g',
    'pronoun_g_acc',
    'pronoun_g_prep',
    'pronoun_d',
    'pronoun_d_acc',
    'pronoun_d_prep',
    'pronoun_a',
    'pronoun_a_acc',
    'pronoun_a_prep',
    'pronoun_i',
    'pronoun_l',
    'pronoun_all',
    'adjective_n',
    'adjective_n_c',
    'adjective_n_k',
    'adjective_ll',
    'adjective_middle',
    'verb_end_inter',
    'verb_end_about',
    'verb_middle_inter',
    'verb_middle_inter_soft',
    'verb_middle_about',
    'verb_nasal',
    'verb_go',
    'verb_o',
    'honorific',
] as const;
